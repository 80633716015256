import h from "hyperscript";
import appleBadge from "res/badges/apple.badge.uk.svg";
import banner from "res/banner.jpg";
import screenshot from "res/screenshot.jpg";

const googleBadge = "https://play.google.com/intl/en_gb/badges/static/images/badges/en_badge_web_generic.png";

export class HomePageView {

    constructor() {
        this.root = h("div.main", [
            h("header.header", {style: {"background-image": `url(${banner})`,}}, [
                h("h1.title", ["Ski Nav"])
            ]),
            h("section.content", [
                h("div.intro", {
                    // innerHTML: "See your <strong>location</strong> on a <strong>piste map</strong> and get point-to-point <strong>route directions</strong>."
                    innerHTML: "Get point-to-point <strong>route directions</strong> and <strong>share your location</strong> on a piste map."
                }),
                h("div.badges", [h("div", [
                    h("a.badge.apple", {href: "https://apps.apple.com/app/id1485113759"}, [
                        h("img", {alt: "Download on the App Store", src: appleBadge})
                    ]),
                    h("a.badge.google", {
                        href: "https://play.google.com/store/apps/details?id=ski.nav.piste.plan&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    }, [
                        h("img", {alt: "Get it on Google Play", src: googleBadge})
                    ])
                ])]),
                h("a.screenshot" /*"div.screenshot"*/, {href: "/demo/index.html"}, [
                    h("img.screenshot", {
                        alt: "Screenshot",
                        src: screenshot
                    })
                ]),
                h("a.demo", {href: "/demo/index.html"}, [
                    "Try a Demo in Your Browser"
                ])
            ])
        ]);
    }
}
